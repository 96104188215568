<template>
  <div id="site-body" class="shop-cart-page" :style="getStyle" :class="{'is-empty': isEmpty}">
    <header-widget v-if="model.widgetList?.[0]" :site="model" :device="deviceType" :model="model.widgetList[0]" />
    <div v-if="deviceType === DeviceEnum.DESKTOP" class="p-6">
      <div class="shop-cart-page__content" :class="{ 'is-empty': isEmpty }">
        <template v-if="shopCartList.length || loseShopCartList.length">
          <div class="header-area">
            <div class="title text-[32px]">{{ $t('siteBuild.shopCart.title') }}</div>
            <div class="subtitle text-14 flex items-center w-full justify-between">
              <div class="flex items-center cursor-pointer hover:text-primary">
                <i class="el-icon-back" />
                <span class="ml-3" @click="handleBack">{{ $t('siteBuild.shopCart.backList') }}</span>
              </div>
              <div v-if="selectList.length" class="hover:text-primary cursor-pointer" @click="handleRemoveAll">
                {{ $t('siteBuild.shopCart.allDelete') }}
              </div>
            </div>
          </div>
          <div class="tool-bar flex items-center text-black">
            <div class="w-[15%] pl-[68px] flex items-center">
              <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="onSelectAllChange" />
              <span class="ml-2">{{ $t('siteBuild.shopCart.selectAll') }}</span>
            </div>
            <div class="w-[40%]">
              <span>{{ $t('siteBuild.shopCart.goodsName') }}</span>
            </div>
            <div class="w-[20%]">
              <span>{{ $t('siteBuild.shopCart.number') }}</span>
            </div>
            <div class="w-[15%]">
              <span>{{ $t('siteBuild.shopCart.price') }}</span>
            </div>
            <div class="w-[10%]">
              <span>{{ $t('siteBuild.shopCart.handle') }}</span>
            </div>
          </div>
          <div v-loading="listLoading" class="shop-cart-page__wrapper">
            <transition-group name="el-fade-in" tag="ul" class="shop-cart-page__list text-black" :style="{ height: `${listHeight}px` }">
              <div v-for="(item, index) in $store.state.goods.shopCartList" :key="item.goods_id" class="item flex items-center w-full flex-wrap" :class="{ 'is-disabled': item.goods_status !== 1 }" :style="getItemStyle(shopCartList, item, index -1)">
                <div class="w-[15%] pl-[68px]">
                  <el-checkbox :value="item.goods_selected" :disabled="item.goods_status !== ShopCartGoodsStatusEnum.normal" @change="(checked) => onSelectChange(checked, item)" />
                </div>
                <div class="w-[40%] flex items-center">
                  <div class="goods-pic flex-shrink-0">
                    <img class="w-full h-full rounded" :src="item.goods_icon" alt="">
                  </div>
                  <div class="name ml-6">
                    <div class="title text-[22px]">{{ getGoodsName(item.goods_names) }}</div>
                    <div class="text-[#999] describe">{{ getGoodDescription(item.goods_descriptions) }}</div>
                    <div v-if="item.goods_limits" class="border mt-2 w-fit rounded border-[#333] px-1 text-[#333] text-14">
                      Limit {{ item.goods_purchased_num }}/{{ item.goods_limits }}
                    </div>
                  </div>
                </div>
                <div class="w-[20%]">
<!--                  <im-input-number v-loading="numberLoading && currentGoods.goods_id === item.goods_id" :number="item.goods_num" @change="(number) => onNumberChange(item, number)"/>-->
                  <input-number v-loading="numberLoading && currentGoods.goods_id === item.goods_id" :min="1" size="small" :value="item.goods_num" @change="(number) => onNumberChange(item, number)" />
                </div>
                <div class="w-[15%] text-[24px] flex items-center">
                  <div>
                    <p>{{ priceMap[item.goods_id].priceTxt }}</p>
                    <p v-if="priceMap[item.goods_id].discount > 0" class="text-14 mt-1 line-through	 text-[#999]">{{ priceMap[item.goods_id].originPriceTxt }}</p>
                  </div>
                </div>
                <div class="w-[10%] text-right pr-[68px] flex justify-end">
                  <div class="w-[24px] h-[24px] cursor-pointer rounded-sm flex items-center justify-center hover:bg-[#EBEEF1]" @click="handleRemove(item, index)">
                    <i class="el-icon-close text-[#999] text-16" />
                  </div>
                </div>
                <div v-if="item.goods_biz_list" class="gift-box w-full">
                  <div class="text-[#999]">{{ $t('siteBuild.shopCart.gift') }}</div>
                  <div class="flex items-center mt-3 w-full overflow-x-auto">
                    <template v-for="biz in item.goods_biz_list">
                      <div v-for="gift in biz.biz_gift_list" :key="gift.gift_goods_id" class="gift-item flex items-center mr-6 flex-shrink-0">
                        <img class="w-[40px] h-[40px] rounded" :src="gift.logo" alt="">
                        <div class="flex items-center ml-3 text-[#999]">
                          <i class="el-icon-close"></i>
                          <span class="text-14">{{ gift.num }}</span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </transition-group>
            <div v-if="loseShopCartList.length" class="shop-cart-page__lose border-t">
              <div class="pl-[68px] text-black text-16 pt-8 h-[52px]">共{{ loseShopCartList.length }}件已失效的商品</div>
              <transition-group name="el-fade-in" tag="ul" class="shop-cart-page__list" :style="{ height: `${loseListHeight}px` }">
                <div  v-for="(item, index) in loseShopCartList" :key="item.goods_id" class="item flex items-center w-full flex-wrap" :style="getItemStyle(loseShopCartList, item, index -1)">
                  <div class="w-[15%] pl-[68px]">
                    <el-checkbox disabled />
                  </div>
                  <div class="w-[40%] flex items-center">
                    <div class="goods-pic flex-shrink-0 relative">
                      <img class="w-full h-full rounded" :src="item.goods_icon" alt="">
                      <div class="lose-cover">已失效</div>
                    </div>
                    <div class="name ml-6">
                      <div class="title text-black text-[22px]">{{ getGoodsName(item.goods_names) }}</div>
                      <div class="text-[#999] describe">{{ getGoodDescription(item.goods_descriptions) }}</div>
                    </div>
                  </div>
                  <div class="w-[20%]">
                    <im-input-number disabled :number="item.goods_num" />
                  </div>
                  <div class="w-[15%] text-[24px] text-black">
                    <p>{{ getShopCartGoodsPrice(item)?.priceTxt }}</p>
                  </div>
                  <div class="w-[10%] text-right pr-[68px] flex justify-end">
                    <div class="w-[24px] h-[24px] cursor-pointer rounded-sm flex items-center justify-center hover:bg-[#EBEEF1]" @click="handleRemoveLoseItem(item, index)">
                      <i class="el-icon-close text-[#999] text-16" />
                    </div>
                  </div>
                  <div v-if="item.goods_biz_list" class="gift-box w-full">
                    <div class="text-[#999]">{{ $t('siteBuild.shopCart.gift') }}</div>
                    <div class="flex items-center mt-3 w-full overflow-x-auto">
                      <template v-for="biz in item.goods_biz_list">
                        <div v-for="gift in biz.biz_gift_list" :key="gift.gift_goods_id" class="gift-item flex items-center mr-6 flex-shrink-0">
                          <img class="w-[40px] h-[40px] rounded" :src="gift.logo" alt="">
                          <div class="flex items-center ml-3 text-[#999]">
                            <i class="el-icon-close"></i>
                            <span class="text-14">{{ gift.num }}</span>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </transition-group>
            </div>
          </div>
          <div class="shop-cart-page__handle">
            <div />
            <div class="flex items-center">
              <div>
                <div class="text-black text-[26px] mr-[56px]">{{ $t("siteBuild.shopCart.total") }} : {{ totalInfo.label }} {{ totalInfo.price }}</div>
                <div v-if="totalInfo.discount" class="text-16">{{ $t('siteBuild.fullDiscount') }}：{{ totalInfo.label }} {{ totalInfo.discount }}</div>
              </div>
              <el-button :loading="loading" class="buy-btn" type="primary" :disabled="submitDisabled" @click="handleSubmit">{{ $t('siteBuild.shopCart.buy') }}({{ totalInfo.number }})</el-button>
            </div>
          </div>
        </template>
        <div v-else-if="isEmpty" class="flex items-center flex-col justify-center">
          <img class="w-[150px]" src="@/assets/img/no-data.png" alt="">
          <div class="text-[#706e6e] text-center">{{ $t('siteBuild.shopCart.noData') }}</div>
          <div class="im-button mt-8" @click="handleBack">{{ $t('siteBuild.shopCart.backList') }}</div>
        </div>
      </div>
    </div>
    <div v-if="deviceType === DeviceEnum.MOBILE" v-loading="listLoading" element-loading-background="#f0efef" class="shop-cart-page__mobile p-2 pb-[40px]" :class="{ 'is-empty': isEmpty }">
      <template v-if="shopCartList.length || loseShopCartList.length">
        <div class="flex items-center text-black px-4 py-2" @click="handleBack">
          <i class="el-icon-back text-16 mr-2" />
          <span>{{ $t('siteBuild.shopCart.backList') }}</span>
        </div>
        <div v-if="shopCartList.length" class="handle-box w-full flex items-center justify-between text-black mt-3">
          <el-checkbox v-model="checkAll" class="text-black font-normal" :indeterminate="isIndeterminate" @change="onSelectAllChange">{{ $t('siteBuild.shopCart.selectAll') }}</el-checkbox>
          <span v-if="selectList.length" @click="handleRemoveAll">{{ $t('siteBuild.shopCart.allDelete') }}</span>
        </div>
        <div class="list-box">
          <div v-for="item in shopCartList" :key="item.goods_id" class="list-box__item" :class="{ 'is-disabled': item.goods_status !== ShopCartGoodsStatusEnum.normal }">
            <div class="title flex items-center justify-between">
              <el-checkbox class="text-black text-18" :value="item.goods_selected" :disabled="item.goods_status !== ShopCartGoodsStatusEnum.normal" @change="(checked) => onSelectChange(checked, item)">{{ getGoodsName(item.goods_names) }}</el-checkbox>
              <i class="el-icon-close text-16 text-[#999]" @click="handleRemove(item)" />
            </div>
            <div class="flex mt-4">
              <div class="thumb w-[78px] flex-shrink-0 mr-3">
                <img class="rounded w-full h-[78px]" :src="item.goods_icon" alt="">
                <div v-if="item.goods_limits" class="border mt-2 mx-auto border-[#333] rounded text-center text-[#333] text-14">
                  Limit {{ item.goods_purchased_num }}/{{ item.goods_limits }}
                </div>
              </div>
              <div class="info w-full">
                <div class="text-[#999]">{{ getGoodDescription(item.goods_descriptions) }}</div>
                <div class="flex items-center w-full justify-between text-black mt-4">
                  <div>
                    <div class="text-18">{{ priceMap[item.goods_id].priceTxt }}</div>
                    <div class="line-through text-[#999]">{{ priceMap[item.goods_id].originPriceTxt }}</div>
                  </div>
                  <input-number v-loading="numberLoading && currentGoods.goods_id === item.goods_id" :min="1" size="small" :value="item.goods_num" @change="(number) => onNumberChange(item, number)"/>
                </div>
              </div>
            </div>
            <div v-if="item.goods_biz_list" class="gift-box w-full">
              <div class="text-[#999]">{{ $t('siteBuild.shopCart.gift') }}</div>
              <div class="flex items-center mt-3 w-full overflow-x-auto">
                <template v-for="biz in item.goods_biz_list">
                  <div v-for="gift in biz.biz_gift_list" :key="gift.gift_goods_id" class="gift-item flex items-center mr-6 flex-shrink-0">
                    <img class="w-[40px] h-[40px] rounded" :src="gift.logo" alt="">
                    <div class="flex items-center ml-3 text-[#999]">
                      <i class="el-icon-close"></i>
                      <span class="text-14">{{ gift.num }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div v-if="loseShopCartList.length" class="lose-area">
            <div class="text-black mb-3 mt-6">共{{ loseShopCartList.length }}件已失效的商品</div>
            <div v-for="(item, index) in loseShopCartList" :key="item.goods_id" class="list-box__item">
              <div class="title flex items-center justify-between">
                <el-checkbox class="text-black text-18" disabled>{{ getGoodsName(item.goods_names) }}</el-checkbox>
                <i class="el-icon-close text-16 text-[#999]" @click="handleRemoveLoseItem(item, index)" />
              </div>
              <div class="flex mt-4">
                <div class="thumb w-[78px] h-[78px] flex-shrink-0 mr-3 relative">
                  <img class="rounded w-full h-full" :src="item.goods_icon" alt="">
                  <div class="lose-cover flex items-center justify-center">已失效</div>
                </div>
                <div class="info w-full">
                  <div class="text-[#999]">{{ getGoodDescription(item.goods_descriptions) }}</div>
                  <div class="flex items-center w-full justify-between text-black mt-4">
                    <span class="text-18">{{ getShopCartGoodsPrice(item)?.priceTxt }}</span>
                    <im-input-number size="mini" :number="item.goods_num" disabled />
                  </div>
                </div>
              </div>
              <div v-if="item.goods_biz_list" class="gift-box w-full">
                <div class="text-[#999]">{{ $t('siteBuild.shopCart.gift') }}</div>
                <div class="flex items-center mt-3 w-full overflow-x-auto">
                  <template v-for="biz in item.goods_biz_list">
                    <div v-for="gift in biz.biz_gift_list" :key="gift.gift_goods_id" class="gift-item flex items-center mr-6 flex-shrink-0">
                      <img class="w-[40px] h-[40px] rounded" :src="gift.logo" alt="">
                      <div class="flex items-center ml-3 text-[#999]">
                        <i class="el-icon-close"></i>
                        <span class="text-14">{{ gift.num }}</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-box">
          <div class="text-black">
            <div class="text-18">{{ totalInfo.label }} {{ totalInfo.price }}</div>
            <div v-if="totalInfo.discount" class="text-danger">{{ $t('siteBuild.fullDiscount') }}：{{ totalInfo.label }} {{ totalInfo.discount }}</div>
<!--            <div class="text-[#999]">{{ $t('siteBuild.shopCart.total') }} ({{ totalInfo.number }} item) </div>-->
          </div>
          <el-button class="buy-button" type="primary" :loading="loading" :disabled="submitDisabled" @click="handleSubmit">{{ $t('siteBuild.shopCart.buy') }}({{ totalInfo.number }})</el-button>
        </div>
      </template>
      <div v-else-if="isEmpty" class="flex items-center flex-col justify-center">
        <img class="w-[150px]" src="@/assets/img/no-data.png" alt="">
        <div class="text-[#706e6e] text-center">{{ $t('siteBuild.shopCart.noData') }}</div>
        <div class="im-button h-[40px] text-18 mt-8" @click="handleBack">{{ $t('siteBuild.shopCart.backList') }}</div>
      </div>
    </div>
    <site-modals :device="deviceType" :site="model" />
    <order-modal />
    <desk-pay-modal v-if="deskPay.visible" :store-lang="$store.state.locale.locale" :desk-pay="deskPay" @close="closePayModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import cloneDeep from "lodash.clonedeep"
import { chain } from 'mathjs';
import debounce from "lodash.debounce"
import { Message, InputNumber } from "element-ui"
import isNil from "lodash.isnil"
import Bus from "~/site/model/bus"
import siteMixin from '~/mixins/site'
import goodMixins from "~/site/widgets/storeWidget/oneGood/goodMixins"
import HeaderWidget from "~/site/widgets/headerWidget/index.vue"
import SiteModals from "~/site/components/modals.vue"
import {DeviceEnum} from "~/enums/deviceEnum"
import ImInputNumber from "~/components/common/ImInputNumber.vue"
import DeskPayModal from '~/components/pay/deskPayModal.vue'
import tools from "~/utils"
import {isMobile} from "~/utils/types"
import {isBuildWebSite, PAY_URL} from "~/config"
import {PageEnum} from "~/enums/pageEnum"
import {ShopCartGoodsStatusEnum} from "~/enums/shopCartEnum"
import OrderModal from "~/site/components/orderModal/index.vue";

const isSandBox = !isBuildWebSite
export default {
  name: 'ShopCartPage',
  components: {
    OrderModal,
    ImInputNumber,
    SiteModals,
    HeaderWidget,
    DeskPayModal,
    InputNumber
  },
  mixins: [siteMixin,goodMixins],
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      ShopCartGoodsStatusEnum,
      return_url: '',
      checkAll: false,
      selectList: [],
      numberLoading: false,
      goodsList: [],
      loading: false,
      currencies: [],
      oldNumber: '',
      currenciesMap: {},
      isIndeterminate: false,
      listLoading: true,
      currentGoods: {},
      deskPay: {
        visible: false,
        isGoPay: true,
        payUrl: '',
        orderCode: ''
      }
    }
  },
  computed: {
    ...mapGetters(["shopCartNumber"]),
    getStyle() {
      let style = {}
      const { globalSetting } = this.model
      if (globalSetting) {
        style = globalSetting.__vars
      }
      return style
    },
    isEmpty() {
      return !this.listLoading && (this.shopCartList.length === 0 && this.loseShopCartList.length === 0)
    },
    listHeight() {
      let h = 0
      this.shopCartList.forEach(item => {
        h = (item.goods_biz_list ? 278 : 152) + h
      })
      return h
    },
    loseListHeight() {
      let h = 0
      this.loseShopCartList.forEach(item => {
        h = (item.goods_biz_list ? 278 : 152) + h
      })
      return h
    },
    priceMap() {
      const result = {}
      const total = this.totalInfo
      const discountInfo = this.$store.state.goods.discountInfo
      this.$store.state.goods.shopCartList.forEach((item) => {
        const isSelect = this.selectList.find(goods => goods.goods_id === item.goods_id)
        const priceInfo = this.getShopCartGoodsPrice(item)
        const isDiscountGoods = item.is_discount_flag && isSelect
        const price = priceInfo.localRealPrice
        const isVCurrencyGoods = (discountInfo.full_discount_type === 1 && item.virtual_currency_id === discountInfo.virtual_currency_id) && (isDiscountGoods)
        const isCurrencyGoods = discountInfo.full_discount_type === 2 && (isDiscountGoods)
        result[item.goods_id] = {}
        result[item.goods_id].price = price
        result[item.goods_id].priceTxt = priceInfo.priceTxt
        if (isCurrencyGoods || isVCurrencyGoods) {
          result[item.goods_id].price = this.getMaxDiscountPrice(priceInfo.localRealPrice, total.originPrice, total.normalLocalPrice, total.price)
          result[item.goods_id].priceTxt = `${priceInfo.currencyLabel} ${result[item.goods_id].price}`
          result[item.goods_id].discount = price - result[item.goods_id].price
          result[item.goods_id].originPrice = price
          result[item.goods_id].originPriceTxt = `${priceInfo.currencyLabel} ${price}`
        }
      })
      return result
    },
    submitDisabled() {
      return this.selectList.length === 0
    },
    totalInfo() {
      const discountInfo = this.$store.state.goods.discountInfo
      const round = this.$store.state.user.currencyRound
      const total = this.selectList.reduce((total, item) => {
        const priceInfo = this.getShopCartGoodsPrice(item)
        const vCurrencyId = item.virtual_currency_id
        const vCurrencyPrice = priceInfo.vCurrencyPrice
        const isVCurrencyDiscountGoods = Boolean(item.is_discount_flag && (discountInfo.full_discount_type === 1) && (vCurrencyId === discountInfo.virtual_currency_id))
        const isCurrencyDiscountGoods = item.is_discount_flag && discountInfo.full_discount_type === 2
        total.originPrice = chain(priceInfo.localRealPrice).add(total.originPrice).round(round).value
        if (isVCurrencyDiscountGoods) total.vCurrencyTotalPrice = chain(vCurrencyPrice).add(total.vCurrencyTotalPrice).value
        if (isCurrencyDiscountGoods || isVCurrencyDiscountGoods) {
          total.discountTargetPrice = chain(priceInfo.targetRelPrice).add(total.discountTargetPrice).round(round).value
          total.discountLocalPrice = chain(priceInfo.localRealPrice).add(total.discountLocalPrice).round(round).value
        } else {
          total.normalLocalPrice = chain(total.normalLocalPrice).add(priceInfo.localRealPrice).round(round).value
        }
        total.label = priceInfo.currencyLabel
        total.number += Number(item.goods_num)
        return total
      }, { label: '', vCurrencyTotalPrice: 0, originPrice: 0, discountTargetPrice: 0, normalLocalPrice: 0, discountLocalPrice: 0, number: 0, discount: 0, localPrice: 0})
      const discountPrice = this.getMaxDiscount(total.discountTargetPrice, total.discountLocalPrice, total.vCurrencyTotalPrice)
      total.discount = chain(total.discountLocalPrice).subtract(discountPrice).round(round).value
      total.price = chain(discountPrice).add(total.normalLocalPrice).round(round).value
      total.discountPrice = discountPrice
      // console.log('购物车total', total)
      return total
    },
    DeviceEnum() {
      return DeviceEnum
    },
    baseParams() {
      return {
        player_id: this.$store.state.user.siteLoginUserInfo?.player_id,
        project_id: this.$store.state.project?.projectId,
        site_id: this.$store.state.user.siteLoginUserInfo?.site_id
      }
    },
    deviceType() {
      return this.$store.state.device.device
    },
    shopCartList() {
      return this.$store.state.goods.shopCartList
    },
    loseShopCartList() {
      return this.$store.state.goods.loseShopCartList
    }
  },
  mounted() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      this.init()
    }
    Bus.$on('reloadGood', async (type) => {
      if (type === 'login-out') {
        this.handleBack()
      }
      await this.getShopCart(this)
      this.initShopCartStatus()
    })
  },
  methods: {
    ...mapActions({
      'getShopCart': 'goods/getShopCart'
    }),
    getItemStyle(list, item, prevIndex, gap = 0) {
      const currentIndex = prevIndex + 1
      let t = gap
      const h = item.goods_biz_list ? 278 : 152
      for (let i = 0; i < list.length; i++) {
        const current = list[i]
        const height = current.goods_biz_list ? 278 : 152
        if (i === currentIndex) {
          break
        }
        t = t + height + gap
      }
      return {
        transform: `translateY(${currentIndex === 0 ? 0 : t}px)`,
        height: `${h}px`
      }
    },
    async handleRemoveAll() {
      const params = {
        ...this.baseParams,
        goods_ids: this.selectList.map(item => item.goods_id)
      }
      this.listLoading = true
      const [err] = await this.$utils.to(this.$api.good.deleteShopCartGoods(params))
      this.listLoading = false
      if (!err) {
        this.selectList = []
        this.isIndeterminate = false
        this.$store.commit('goods/REMOVE_SHOP_CART_GOODS', params.goods_ids)
        this.updateCheckAll()
      } else {
        Message.error(err)
      }
    },
    async closePayModal() {
      this.$store.commit('site/SET_SHOP_GOOD', null)
      this.deskPay = {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
      // this.listLoading = true
      await this.getShopCart(this)
      // this.listLoading = false
      this.initShopCartStatus()
    },
    checkStatus() {
      if (isNil(this.$store.state.user.siteLoginUserInfo?.player_id)) {
        this.$store.commit('user/SET_LOGINSHOW', true)
        this.$store.commit('user/SET_SITE_BIND_USER', true)
        throw new Error('Not Found User Info')
      }
    },
    async createOrder() {
      const StoreIpInfo = tools.getLangByip(this)
      const { currency } = StoreIpInfo
      const {
        merchantId,
        projectId
      } = this.$store.state.project
      const ipInfo = tools.getxfip(localStorage) || {}
      const device = isMobile() ? 'mobile' : 'desktop'
      const lang = tools.getNeedParamsFormRouteParams(this.$route.params)?.lang || 'en'
      const params = {
        source: 1,
        project_id: projectId,
        merchant_id: merchantId,
        currency,
        amount: this.totalInfo.price,
        from_preview_mode: !isBuildWebSite,
        items: this.selectList.map(item => {
          const newItem = {...item}
          const priceInfo = this.getRealPriceInfo(newItem.goods_prices)
          const price = +priceInfo.price
          return {
            item_id: newItem.goods_id,
            num: newItem.goods_num,
            amount: price,
            price,
            currency,
            virtual_currency: currency,
            virtual_currency_amount: price
          }
        }),
        settings: {
          device,
          view_source: this.$route.query.from,
          language: lang,
          show_order: false,
          return_url: this.return_url,
        },
      }
      this.loading = true
      const [err, res] = await this.$utils.to(this.$api.order.createOrder(params, ipInfo))
      this.loading = false
      if (!err) {
        this.openPayModal(res)
      } else {
        Message.error(err)
      }
    },
    openPayModal(res) {
      const sandbox = isSandBox ? 1 : 0
      const device = isMobile() ? 'mobile' : 'desktop'
      const { token, code } = res
      let payUrl = ''
      const env = process.env.RUN_ENV
      const isGoPay = token.slice(0, 2) === 'v2'
      if (isGoPay) {
        payUrl = `${PAY_URL[env]}/new-kop/payelement?access_token=${token}&sandbox=${+sandbox}`
      } else {
        payUrl = `${PAY_URL[env]}/pay/payelement?access_token=${token}&sandbox=${+sandbox}`
      }
      if (device === 'mobile') {
        this.$store.commit('site/SHOW_ORDER_MODAL', { payUrl, code })
      } else {
        this.deskPay = {
          visible: true,
          isGoPay,
          payUrl,
          orderCode: code
        }
      }
    },
    async handleSubmit() {
      this.checkStatus()
      await this.createOrder()
    },
    onSelectAllChange(checked) {
      this.isIndeterminate = false
      const getItems = () => {
        return this.selectList.map(item => {
          const newItem = {...item}
          return {
            goods_id: newItem.goods_id,
            goods_num: newItem.goods_num,
            goods_selected: checked ? 1 : 2
          }
        })
      }
      let items = []
      if (checked) {
        this.selectList = cloneDeep(this.$store.state.goods.shopCartList.filter(item => item.goods_status === ShopCartGoodsStatusEnum.normal))
        items = getItems()
      } else {
        items = getItems()
        this.selectList = []
      }
      this.$store.commit('goods/UPDATE_ALL_GOODS_SELECT', checked)
      this.debounceUpdateGoodsSelect(items)
    },
    onSelectChange(checked, item) {
      const good = cloneDeep(item)
      if (checked) {
        this.selectList.push(good)
      } else {
        const index = this.selectList.findIndex(goodItem => goodItem.goods_id === good.goods_id)
        index > -1 && this.selectList.splice(index, 1)
      }
      this.$store.commit('goods/UPDATE_GOOD_SELECT',{ good, checked })
      this.updateCheckAll()
      const items = [
        {
          goods_id: good.goods_id,
          goods_num: good.goods_num,
          goods_selected: checked ? 1 : 2
        }
      ]
      this.debounceUpdateGoodsSelect(items)
    },
    debounceUpdateGoodsSelect: debounce(async function (items) {
      const params = {
        ...this.baseParams,
        goods_item: items
      }
      const [err] = await this.$utils.to(this.$api.good.updateShopCartGoods(params))
      if (!err) {
        //
      }
    }, 400),
    updateCheckAll() {
      this.checkAll =( this.selectList.length === this.$store.state.goods.shopCartList.length) && this.selectList.length !== 0
      this.isIndeterminate = this.selectList.length !== 0 && !this.checkAll
    },
    async getCurrencies() {
      const res = await this.$api.common.getCurrencies()
      this.currencies = res.data
      this.currenciesMap = {}
      this.currencies.forEach((c) => {
        this.currenciesMap[c.id] = c
      })
      this.$store.commit('project/SET_CURRENCYINFO', {
        currency: this.currencies,
        currencieMap: this.currenciesMap,
      })
    },
    async init() {
      this.listLoading = true
      this.setDeviceWidth()
      await this.getUserInfo()
      await this.getShopCart(this)
      this.initShopCartStatus()
      this.listLoading = false
      this.return_url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?from=${this.$route.query?.from}`
    },
    initShopCartStatus() {
      this.selectList = []
      this.shopCartList.forEach(item => {
        if (item.goods_selected) {
          this.selectList.push(cloneDeep(item))
        }
      })
      this.updateCheckAll()
    },
    async handleRemoveLoseItem(item, index) {
      await this.handleRemove(item)
      this.$store.commit('goods/REMOVE_LOSE_ITEM', index)
    },
    updateGoodsNumber(item, number) {
      this.$nextTick(() => {
        const current = this.selectList.find(g => g.goods_id === item.goods_id)
        if (current) {
          current.goods_num = number
        }
        this.$store.commit('goods/UPDATE_GOOD_NUM', { good: item, number })
      })
    },
    async onNumberChange(item, number) {
      this.currentGoods = cloneDeep(item)
      const { goods_id, goods_num } = item
      const params = {
        ...this.baseParams,
        goods_item: [
          {
            goods_id,
            goods_num: Number(number),
            goods_selected: 0
          }
        ]
      }
      this.updateGoodsNumber(item, number)
      this.numberLoading = true
      const [err, res] = await this.$utils.to(this.$api.good.updateShopCartGoods(params))
      this.numberLoading = false
      if (!err) {
        this.$store.commit('goods/SET_SHOP_CART_LIST', res.list)
      } else {
        Message.error(err)
        this.updateGoodsNumber(item, goods_num)
      }
    },
    removeSelectGoods(ids) {
      ids.forEach(id => {
        const index = this.selectList.findIndex(g => g.goods_id === id)
        index > -1 && this.selectList.splice(index, 1)
      })
    },
    async handleRemove(item) {
      const params = {
        ...this.baseParams,
        goods_ids: [item.goods_id]
      }
      const [err] = await this.$utils.to(this.$api.good.deleteShopCartGoods(params))
      if (!err) {
        this.removeSelectGoods(params.goods_ids)
        this.$store.commit('goods/REMOVE_SHOP_CART_GOODS', params.goods_ids)
        this.updateCheckAll()
      }
    },
    handleBack() {
      window.location.href = window.location.href.replace(PageEnum.SHOP_CART, '')
    }
  }
}
</script>

<style lang="less">
.shop-cart-page {
  background-color: #f0efef;
  .im-button {
    width: 186px;
    height: 56px;
    color: var(--button-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    background-color: var(--button-background-color);
    cursor: pointer;
    border-radius: @border-radius-base;
    margin-left: 6px;
  }
  &__content {
    background-color: white;
    max-width: 1280px;
    margin: 0 auto;
    height: calc(100vh - (86px + 50px));
    border-radius: 2px;
    position: relative;
    &.is-empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .header-area {
      background: #F6F6F6;
      color: black;
      padding: 24px 68px;
    }
    .tool-bar {
      height: 56px;
      border-bottom: 1px solid @border-color-1;
    }
  }
  &__wrapper {
    height: calc(100% - 117px - 56px - 108px);
    position: relative;
    overflow: hidden auto;
    &::-webkit-scrollbar-thumb {
      background-color: #999;
    }
  }
  &__list {
    .item {
      width: 100%;
      padding: 32px 0;
      border-bottom: 1px solid @border-color-1;
      transition: 0.3s all ease-in-out;
      position: absolute;
      height: 152px;
      .name {
        .describe {
          width: 300px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .gift-box {
        padding: 20px;
        background-color: #F6F6F6;
        border-radius: @border-radius-base;
        margin: 16px 5% 0 10%;
      }
      &.is-disabled {
        opacity: 0.3;
        pointer-events: none;
      }
      &:last-of-type {
        border-bottom: 0;
      }
      .goods-pic {
        width: 86px;
        height: 86px;
        .lose-cover {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          color: white;
          background-color: rgba(0, 0, 0, 0.6);
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  &__handle {
    width: 100%;
    padding: 0 68px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 108px;
    left: 0;
    bottom: 0;
    border-top: 1px solid @border-color-1;
    .buy-btn {
      width: 186px;
      height: 60px;
      color: var(--button-text-color);
      border: 1px solid var(--button-background-color);
      background-color: var(--button-background-color);
      border-radius: 2px;
      font-size: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.is-disabled {
        opacity: 0.6;
      }
    }
  }
  &__mobile {
    overflow: auto;
    height: calc(100vh - 164px);
    //height: calc(100vh - 60px);
    &.is-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: white;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #f6f6f6;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label {
      color: @primary-1;
    }
    .handle-box {
      background-color: white;
      border-radius: 4px;
      padding: 10px 16px;
    }
    .list-box {
      margin-top: 10px;
      &__item {
        padding: 16px;
        background-color: white;
        border-radius: 4px;
        margin-bottom: 10px;
        &.is-disabled {
          opacity: 0.3;
          pointer-events: none;
        }
        .lose-cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          color: white;
        }
      }
    }
    .bottom-box {
      padding: 24px 16px 30px 16px;
      background-color: #F5F6F7;
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      z-index: 9000;
      .buy-button {
        height: 46px;
        color: var(--button-text-color);
        border: 1px solid var(--button-background-color);
        background-color: var(--button-background-color);
        border-radius: 2px;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.is-disabled {
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
